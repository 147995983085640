import React from 'react'
import {
  useTable, usePagination,
} from 'react-table'
import { Pagination } from '../table/Pagination'
import { KTSVG } from '../../../../_metronic/helpers'
import { QSExportData } from '../../../pages/quotations/Export'
import DatePicker from 'react-datepicker'
import { InputDate } from '../func/CoverTime'

const QSTable = ({
  columns,
  data,
  fetchData,
  loading,
  pageCount: controlledPageCount,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable({
    columns,
    data,
    initialState: { pageIndex: 0 },
    manualPagination: true,
    pageCount: controlledPageCount,
  },
    usePagination
  )

  const [filter, setFilter] = React.useState('');
  const [dateA, setDateA] = React.useState('');
  const [dateB, setDateB] = React.useState('');
  const obj = { dateA: '', dateB: '' };
  const [newdate, setDate] = React.useState(obj);
  const [status, setStatus] = React.useState('all');
  const st = React.useRef('all');

  const handleDate = () => {
    setDate((newdate) => ({ ...newdate, dateA: InputDate(dateA), dateB: InputDate(dateB) }));
    setStatus(st.current);

  }

  const resetDate = () => {
    setDate((newdate) => ({ ...newdate, dateA: '', dateB: '' }));
    setDateA('');
    setDateB('');
    setStatus('all');
  }

  const handleFilter = (value) => {
    gotoPage(0);
    setFilter(value);
  }


  React.useEffect(() => {
    fetchData({ pageIndex, pageSize, filter, newdate, status });
  }, [fetchData, pageIndex, pageSize, filter, newdate, status]);


  return (
    <>
      <div className='card'>
        <div className='card-header'>
          <div className='card-title'>
            <div className='d-flex align-items-center position-relative my-1'>
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-1 position-absolute ms-6'
              />
              <input id='search' type='text' data-kt-user-table-filter='search'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Search'
                onInput={(e) => {
                  handleFilter(e.target.value)
                }} />
            </div>
          </div>
          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-light-primary me-3'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
            >
              <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
              Filter
            </button>
            <div className='menu menu-sub menu-sub-dropdown w-350px w-md-380px' data-kt-menu='true'>
              <div className='px-7 py-5'>
                <div className='fs-5 text-dark fw-bold'>Filter Options</div>
              </div>

              <div className='separator border-gray-200'></div>

              <div className='px-7 py-5' data-kt-user-table-filter='form'>
                <div className='mb-5'>
                  <label className='form-label fs-6 fw-semibold'>Date:</label>
                  <DatePicker
                    className='form-control form-control-solid'
                    selected={dateA}
                    onChange={(date) => {
                      setDateA(date)
                    }}
                    dateFormat='yyyy/MM/dd'
                    maxDate={new Date('12-31-9999')}
                    placeholderText="yyyy/mm/dd"
                    shouldCloseOnSelect={false}
                  />
                </div>

                <div className='mb-5' data-kt-menu-trigger='none'>
                  <label className='form-label fs-6 fw-semibold'>to:</label>
                  <DatePicker
                    className='form-control form-control-solid'
                    selected={dateB}
                    onChange={(date) => {
                      setDateB(date);
                    }}
                    dateFormat='yyyy/MM/dd'
                    maxDate={new Date('12-31-9999')}
                    placeholderText="yyyy/mm/dd"
                    shouldCloseOnSelect={false}
                  />
                </div>

                <div className='mb-5' data-kt-menu-trigger='none'>
                  <label className='form-label fs-6 fw-semibold'>Status:</label>
                  <select id='status' className='form-select form-select-solid' onChange={(e) => { st.current = e.target.value }}>
                    <option value="all" selected={status == 'all' ? true : false}>all</option>
                    <option value="none" selected={status == 'none' ? true : false}>none</option>
                    <option value="return" selected={status == 'return' ? true : false}>return</option>
                    <option value="submit" selected={status == 'submit' ? true : false}>submit</option>
                    <option value="processing" selected={status == 'processing' ? true : false}>processing</option>
                    <option value="finished" selected={status == 'finished' ? true : false}>finished</option>
                    <option value="cancel" selected={status == 'cancel' ? true : false}>cancel</option>
                    <option value="revised" selected={status == 'revised' ? true : false}>revised</option>
                  </select>
                </div>

                <div className='d-flex justify-content-end'>
                  <QSExportData />
                  <button
                    type='button'
                    className='btn btn-light btn-active-light-primary fw-semibold me-2 mx-2 px-6'
                    data-kt-menu-dismiss='true'
                    data-kt-user-table-filter='reset'
                    onClick={handleDate}
                  >
                    Find
                  </button>
                  <button
                    type='button'
                    className='btn btn-danger fw-semibold px-6'
                    data-kt-menu-dismiss='true'
                    data-kt-user-table-filter='filter'
                    onClick={resetDate}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='card-body'>
          <div className='table-responsive' style={{ maxHeight: '550px' }}>
            <table {...getTableProps()} className='table table-sm table-hover table-row-bordered align-middle gy-4 gs-9 text-center'>
              <thead className='border-bottom border-gray-200 fs-6 text-gray-600 fw-bold bg-light header-table-sticky'>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps([
                          {
                            className: column.headerClassName,
                          },
                        ])}
                      >
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className='fw-semibold text-gray-600'>
                {page.length > 0 ? (
                  <>
                    {page.map((row, i) => {
                      prepareRow(row)
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map(cell => {
                            return <td {...cell.getCellProps([
                              {
                                className: cell.column.columnClassName,
                              },
                            ])}>{cell.render('Cell')}</td>
                          })}
                        </tr>
                      )
                    })}
                    <tr>
                      {loading ? (
                        <td colSpan={columns.length}>Loading...</td>
                      ) : (
                        ''
                      )}
                    </tr>
                  </>
                ) : (
                  <tr>
                    <td colSpan={columns.length}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No matching records found
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className='header-table-sticky'>
            <Pagination pageOptions={pageOptions}
              pageIndex={pageIndex}
              gotoPage={gotoPage}
              previousPage={previousPage}
              nextPage={nextPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              pageCount={pageCount} />
          </div>
        </div>
      </div >
    </>
  )
}

export { QSTable }
