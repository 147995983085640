import axios from 'axios'
import {RemarkModel} from '../models/RemarkModel'

const API_URL = process.env.REACT_APP_API_URL

export const REMARK = `${API_URL}/remark`
export const REMARK_HISTORY = `${API_URL}/remark-hitory`
export const REMARK_GROUP_HISTORY = `${API_URL}/remark-group-hitory`

export function RemarkHistories(quotation_id: number) {
  return axios.get<RemarkModel>(REMARK_HISTORY + `/${quotation_id}`)
}

export function RemarkGroupHistories(job_id: number) {
  return axios.get<RemarkModel>(REMARK_GROUP_HISTORY + `/${job_id}`)
}
