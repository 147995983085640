import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPen} from '@fortawesome/free-solid-svg-icons'

const statusFormat = (status: string, data?: any) => {
  const penIcon = <FontAwesomeIcon icon={faPen} className='fa-fw ms-1 text-orange' size='sm' />

  if (status == 'none') {
    return <span className='badge badge-light-secondary text-dark'>none</span>
  } else if (status == 'return') {
    return <span className='badge badge-light-danger'>return</span>
  } else if (status == 'submit') {
    return <span className='badge badge-light-warning'>submit</span>
  } else if (status == 'processing') {
    return <span className='badge badge-light-info'>processing</span>
  } else if (status == 'finished') {
    if (data && data?.updated_at && data?.finished_date) {
      const finished_date: any = new Date(data?.finished_date)
      const updated_at: any = new Date(data?.updated_at)

      if (finished_date < updated_at) {
        return (
          <div className='d-flex'>
            <span className='badge badge-light-success'>finished {penIcon}</span>
          </div>
        )
      }
    }
    return <span className='badge badge-light-success'>finished</span>
  } else if (status == 'cancel') {
    return <span className='badge badge-danger text-white'>cancel</span>
  } else if (status == 'revised') {
    return <span className='badge badge-light-orange'>revised</span>
  }
}

export {statusFormat}
