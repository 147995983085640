import {useEffect, useState, useRef} from 'react'
import {Modal, Button} from 'react-bootstrap'
import Select from 'react-select'
import {KTSVG} from '../../../_metronic/helpers'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faUpRightAndDownLeftFromCenter,
  faDownLeftAndUpRightToCenter,
  faSave,
  faCalendarDays,
} from '@fortawesome/free-solid-svg-icons'
import {QSUpdate} from '../../modules/api/requests/QuotationRequest'
import {DeptOption} from '../../modules/api/requests/DeptRequest'
import {ItemGroupOption} from '../../modules/api/requests/ItemGroupRequest'
import {ItemOption, ItemFind, ItemUpdate} from '../../modules/api/requests/ItemRequest'
import {SUPOption} from '../../modules/api/requests/SupplierRequest'
import {UnitOption} from '../../modules/api/requests/UnitRequest'
import {BranchOption} from '../../modules/api/requests/BranchRequest'
import {CoverTime4, CovertTime, InputDate} from '../../modules/widgets/func/CoverTime'
import {OpenFile} from '../../modules/widgets/func/OpenFile'
import {UploadFiles} from '../../modules/widgets/quotation/func/UploadFiles'
import Swal from 'sweetalert2'
import {customStyles} from '../../modules/widgets/Styles/ReactSelect'
import DatePicker from 'react-datepicker'
import {BGLoad, BGClose} from '../../modules/widgets/func/BackgroundLoading'
import {statusFormat} from '../../modules/widgets/quotation/func/QSStatus'
import {RemarkHistories} from '../../modules/api/requests/RemarkRequest'
import {UndoQuotationList} from '../../modules/api/requests/UndoRequest'
import {SendUpdatedQuotation} from '../../modules/api/requests/MailRequest'
import {useAuth} from '../../modules/auth'

const QSEdit = ({data, getData}: any) => {
  // --- session
  const {currentUser} = useAuth()
  let role: any = currentUser?.roles || null

  // --- state
  const [show, setShow] = useState(false)
  const [fullscreen, setFullscreen] = useState<any>('')
  const [depts, setDepts] = useState([])
  const [newdata, setNewData] = useState<any>({})
  const [igs, setIG] = useState([])
  const [igFull, setIGFull] = useState([])
  const [items, setItems] = useState([])
  const [units, setUnits] = useState([])
  const [branches, setBranches] = useState([])
  const [sups, setSub] = useState([])
  const [boxItem, setBoxItem] = useState(false)
  const [newItem, setNewItem] = useState({
    id: 0,
    title: '',
    price: 0,
    qty: 0,
    item_group_id: '',
    unit_id: '',
    description: '',
    active: 0,
  })

  const down = <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} className='fa-fw' />
  const up = <FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} className='fa-fw' />
  const save = <FontAwesomeIcon icon={faSave} className='fa-fw' />
  const calendar = <FontAwesomeIcon icon={faCalendarDays} className='fa-fw text-primary' />

  const formButtonRef: any = useRef(null)

  const handleClose = () => {
    setShow(false)
    setRemark([])
    setQuotationHistories([])
  }

  const handleShow = () => {
    formButtonRef.current.blur()

    let box_sup = false
    let box_item = false

    if (data.supplier_other != null) {
      box_sup = true
    }

    if (data.item_other != null) {
      box_item = true
    }

    setNewData((newdata: any) => ({
      ...newdata,
      id: data.id || null,
      job_no: data.job_no || null,
      job_user_no: data.job_user_no || null,
      requestor_id: data.requestor_id || null,
      status: data.status || null,
      require_date: data.require_date || null,
      finished_date: data.finished_date || null,
      dept_id: data.dept_id || null,
      for_dept_id: data.for_dept_id || null,
      urgency: data.urgency || null,
      supplier_id: data.supplier_id || null,
      supplier_other: data.supplier_other || null,
      item_group_id: data.item_group_id || null,
      item_id: data.item_id || null,
      item_other: data.item_other || null,
      qty: data.qty || 0,
      unit_id: data.unit_id || null,
      file_url_pic: data.file_url_pic || null,
      file_url_draw: data.file_url_draw || null,
      file_url_qto: data.file_url_qto || null,
      branch_id: data.branch_id || null,
      gp_verify_id: data.gp_verify_id || null,
      gp_verify_comment: data.gp_verify_comment || null,
      created_at: data.created_at || null,
      updated_at: data.updated_at || null,
      job_title: data.job_title || null,
      pic: data.pic || null,
      requestor_name: data.requestor_name || null,
      dept_title: data.dept_title || null,
      for_dept_title: data.for_dept_title || null,
      ig_title: data.ig_title || null,
      item_title: data.item_title || null,
      item_description: data.item_description || null,
      unit_title: data.unit_title || null,
      supplier_title: data.supplier_title || null,
      box_sup: box_sup,
      box_item: box_item,
      remark: data.remark_content,
    }))
    getDept()
    getIG()
    getItem()
    getSub()
    getUnit()
    getBranch()
    getItemDescription(data.item_id)
    getRemark()
    getQuotationHistories()
    setShow(true)
  }

  const handleData = (key: string, value: any) => {
    setNewData((newdata: any) => ({...newdata, [key]: value}))
  }

  const handleItem = (key: string, value: any) => {
    setNewItem((newItem: any) => ({...newItem, [key]: value}))
  }

  const urgent = (bool: any) => {
    if (bool == 1) {
      return true
    } else {
      return false
    }
  }

  const openFull = () => {
    if (fullscreen === true) {
      setFullscreen('')
    } else {
      setFullscreen(true)
    }
  }

  const getDept = async () => {
    let options: any = []
    const res: any = await DeptOption()
    if (res.data.data.length > 0) {
      res.data.data.map((val: any) => {
        options.push({value: val.id, label: val.dept_name})
      })
      setDepts(options)
    }
  }

  const getIG = async () => {
    let options: any = []
    const res: any = await ItemGroupOption()
    if (res.data.data.length > 0) {
      res.data.data.map((val: any) => {
        options.push({value: val.id, label: val.title})
      })
      setIG(options)
    }
    setIGFull(res.data.data)
  }

  const getItem = async () => {
    let options: any = []
    const res: any = await ItemOption()
    if (res.data.data.length > 0) {
      res.data.data.map((val: any) => {
        options.push({value: val.id, label: val.title})
      })
      setItems(options)
    }
  }

  const getItemDescription = async (id: number) => {
    const item_id: string = `${id}`
    const res: any = await ItemFind(item_id)
    if (res.data.data) {
      setNewItem(res.data.data)
    }
  }

  const getUnit = async () => {
    let options: any = []
    const res: any = await UnitOption()
    if (res.data.data.length > 0) {
      res.data.data.map((val: any) => {
        options.push({value: val.id, label: val.title})
      })
      setUnits(options)
    }
  }

  const getBranch = async () => {
    let options: any = []
    const res: any = await BranchOption()
    if (res.data.data.length > 0) {
      res.data.data.map((val: any) => {
        options.push({value: val.id, label: val.title})
      })
      setBranches(options)
    }
  }

  const getSub = async () => {
    let options: any = []
    const res: any = await SUPOption()
    if (res.data.data.length > 0) {
      res.data.data.map((val: any) => {
        options.push({value: val.id, label: val.title})
      })
      setSub(options)
    }
  }

  const changeBox = (value: any) => {
    const res: any = igFull.find((val: any) => val.id == value)
    if (res.custom_item != '' || typeof res.custom_item != 'undefined') {
      if (res.custom_item == 1) {
        handleData('box_item', true)
        setNewItem({
          id: 0,
          title: '',
          price: 0,
          qty: 0,
          item_group_id: '',
          unit_id: '',
          description: '',
          active: 0,
        })
      } else {
        handleData('box_item', false)
        if (
          newdata.item_id != null &&
          typeof newdata.item_id !== 'undefined' &&
          newdata.item_id != ''
        ) {
          getItemDescription(newdata.item_id)
        }
      }
    }
  }

  const numberOnly = (event: any) => {
    let input = event.target.value
    event.target.value = input
      .replace(/[^0-9.]/g, '')
      .replace(/(\..*?)\..*/g, '$1')
      .replace(/^0[^.]/, '0')
  }

  const UpdateNewItem = async () => {
    const id = newItem.id
    if (id) {
      Swal.fire({
        title: 'Are you confirm to update item description?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        heightAuto: false,
        returnFocus: false,
        showLoaderOnConfirm: true,
        preConfirm: (e) => {
          return new Promise(async function (resolve) {
            await ItemUpdate(
              newItem.id,
              newItem.title,
              newItem.price,
              newItem.qty,
              newItem.item_group_id,
              newItem.unit_id,
              newItem.description,
              newItem.active
            )
              .then((res) => {
                if (res.data.status == true) {
                  Swal.fire({
                    title: 'Successful updated!',
                    icon: 'success',
                    showConfirmButton: false,
                    heightAuto: false,
                    timer: 1500,
                    returnFocus: false,
                  }).then(() => {})
                }
              })
              .catch((err) => {
                Swal.fire({
                  title: 'Failed to update',
                  icon: 'error',
                  heightAuto: false,
                  returnFocus: false,
                })
              })
          })
        },
      })
    } else {
      Swal.fire({
        title: 'Please check the ID.',
        text: `Can't update Item Description because item id not found !`,
        icon: 'error',
        heightAuto: false,
        returnFocus: false,
      })
    }
  }

  const submit = async () => {
    const id = newdata.id
    const require_date = CovertTime(newdata.require_date || null)
    const dept_id = newdata.dept_id || null
    const for_dept_id = newdata.for_dept_id || null
    const urgency = newdata.urgency || null
    const item_group_id = newdata.item_group_id || null
    let item_id = newdata.item_id || null
    let item_other = newdata.item_other || null
    const qty = newdata.qty || null
    const unit_id = newdata.unit_id || null
    let supplier_id = newdata.supplier_id || null
    let supplier_other = newdata.supplier_other || null
    const file_url_pic = newdata.file_url_pic || null
    const file_url_draw = newdata.file_url_draw || null
    const file_url_qto = newdata.file_url_qto || null
    const branch_id = newdata.branch_id || null
    const status = newdata.status || null
    const remark = newdata.remark || null

    console.log(newdata)

    if (newdata.box_item == true) {
      item_id = null
    } else {
      item_other = null
    }

    if (newdata.box_sup == true) {
      supplier_id = null
    } else {
      supplier_other = null
    }

    if (id == null || typeof id === 'undefined' || id == '') {
      Swal.fire({
        title: 'Please check the ID.',
        text: 'This Quotation ID is null or undefined.',
        icon: 'error',
        heightAuto: false,
        returnFocus: false,
      })
    } else {
      Swal.fire({
        title: 'Are you confirm to save?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        heightAuto: false,
        returnFocus: false,
        showLoaderOnConfirm: true,
        preConfirm: (e) => {
          return new Promise(async function (resolve) {
            await QSUpdate(
              id,
              require_date,
              dept_id,
              for_dept_id,
              urgency,
              item_group_id,
              item_id,
              item_other,
              qty,
              unit_id,
              supplier_id,
              supplier_other,
              file_url_pic,
              file_url_draw,
              file_url_qto,
              branch_id,
              status,
              remark
            )
              .then(async (res) => {
                if (res.data.status == true) {
                  if (status === 'finished') {
                    await SendUpdatedQuotation(id)
                  }
                  Swal.fire({
                    title: 'Successful updated!',
                    icon: 'success',
                    showConfirmButton: false,
                    heightAuto: false,
                    timer: 1500,
                    returnFocus: false,
                  }).then(() => {
                    getData()
                    handleClose()
                  })
                } else {
                  Swal.fire({
                    title: res.data.message,
                    icon: 'error',
                    heightAuto: false,
                    returnFocus: false,
                  })
                }
              })
              .catch((err) => {
                Swal.fire({
                  title: 'Failed to update',
                  icon: 'error',
                  heightAuto: false,
                  returnFocus: false,
                })
              })
          })
        },
      })
    }
  }

  // --- remark
  const [remarks, setRemark] = useState([])

  const getRemark = async () => {
    try {
      const res: any = await RemarkHistories(data.id)
      if (res.data.data.length > 0) {
        setRemark(res.data.data)
      } else {
        setRemark([])
      }
    } catch (err) {
      console.error(err)
    }
  }

  // --- quotation histories
  const [quotationHistories, setQuotationHistories] = useState([])

  const getQuotationHistories = async () => {
    try {
      const res: any = await UndoQuotationList(data.id)
      if (res.data.data.length > 0) {
        setQuotationHistories(res.data.data)
      } else {
        setQuotationHistories([])
      }
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      <button
        ref={formButtonRef}
        className='btn btn-icon btn-light-primary btn-sm me-2'
        onClick={handleShow}
        disabled={
          data.status == 'none' ||
          data.status == 'return' ||
          data.status == 'revised' ||
          (data.status == 'finished' && role?.id == 1)
            ? false
            : true
        }
      >
        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
      </button>
      <Modal show={show} onHide={handleClose} size='xl' fullscreen={fullscreen} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>Quotation</h2>
          </Modal.Title>
          <button
            className='btn btn-light-white btn-sm'
            onClick={() => {
              openFull()
            }}
          >
            {fullscreen != true ? down : up}
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='col-md-10 border-right'>
              <div className='row'>
                <div className='col-md-12 mb-2'>
                  <div className='form-group'>
                    <label htmlFor='' className='fw-bold fs-6 mb-2'>
                      Requestor
                    </label>
                    <input
                      type='text'
                      className='form-control form-control-sm form-control-solid'
                      value={newdata.requestor_name || '-'}
                      readOnly
                    />
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='form-group mb-2'>
                    <label htmlFor='gp' className='fw-bold mb-2'>
                      GP Verify
                    </label>
                    <input
                      type='text'
                      id='gp'
                      className='form-control form-control-sm form-control-solid'
                      value={newdata.pic || '-'}
                      readOnly
                    />
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='form-group mb-2'>
                    <label htmlFor='gp_comment' className='fw-bold mb-2'>
                      GP Comment
                    </label>
                    <textarea
                      id='gp_comment'
                      rows={1}
                      className='form-control form-control-sm form-control-solid'
                      value={newdata.gp_verify_comment || '-'}
                      readOnly
                    ></textarea>
                  </div>
                </div>
                <div className='col-md-3'>
                  <div className='form-group mb-2'>
                    <label htmlFor='job_no' className='fw-bold mb-2'>
                      GP no
                    </label>
                    <input
                      type='text'
                      id='job_no'
                      className='form-control form-control-sm form-control-solid'
                      value={newdata.job_title || '-'}
                      readOnly
                    />
                  </div>
                </div>
                <div className='col-md-3'>
                  <div className='form-group mb-2'>
                    <label htmlFor='job_user_no' className='fw-bold mb-2'>
                      Job User no
                    </label>
                    <input
                      type='text'
                      id='job_user_no'
                      className='form-control form-control-sm form-control-solid'
                      value={newdata.job_user_no || '-'}
                      readOnly
                    />
                  </div>
                </div>
                <div className='col-md-3'>
                  <div className='form-group mb-2'>
                    <label htmlFor='dept_id' className='fw-bold required mb-2'>
                      Section/Department
                    </label>
                    <Select
                      id='dept_id'
                      options={depts}
                      onChange={(option: any) => {
                        handleData('dept_id', option.value)
                      }}
                      value={
                        depts ? depts.find((option: any) => option.value === newdata.dept_id) : ''
                      }
                      styles={customStyles}
                    />
                  </div>
                </div>
                <div className='col-md-3'>
                  <div className='form-group mb-2'>
                    <label htmlFor='for_dept_id' className='fw-bold required mb-2'>
                      For&nbsp;Dept.
                    </label>
                    <Select
                      id='for_dept_id'
                      options={depts}
                      onChange={(option: any) => {
                        handleData('for_dept_id', option.value)
                      }}
                      value={
                        depts
                          ? depts.find((option: any) => option.value === newdata.for_dept_id)
                          : ''
                      }
                      styles={customStyles}
                    />
                  </div>
                </div>
                <div className='col-md-3'>
                  <div className='form-group mb-2'>
                    <label htmlFor='item_group_id' className='fw-bold required mb-2'>
                      Item&nbsp;Group
                    </label>
                    <Select
                      id='item_group_id'
                      options={igs}
                      onChange={(option: any) => {
                        handleData('item_group_id', option.value)
                        changeBox(option.value)
                      }}
                      value={
                        igs ? igs.find((option: any) => option.value === newdata.item_group_id) : ''
                      }
                      styles={customStyles}
                    />
                  </div>
                </div>
                <div className='col-md-3'>
                  <div className='form-group mb-2'>
                    <label htmlFor='item_id' className='fw-bold required mb-2'>
                      Item
                    </label>
                    {newdata.box_item ? (
                      <input
                        type='text'
                        id='item_other'
                        className='form-control form-control-sm border border-danger'
                        placeholder='Please fill out'
                        maxLength={255}
                        onChange={(e) => {
                          handleData('item_other', e.target.value)
                        }}
                        value={newdata.item_other}
                      />
                    ) : (
                      <Select
                        id='item_group_id'
                        options={items}
                        onChange={(option: any) => {
                          handleData('item_id', option.value)
                          getItemDescription(option.value)
                        }}
                        value={
                          items ? items.find((option: any) => option.value === newdata.item_id) : ''
                        }
                        styles={customStyles}
                      />
                    )}
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='form-group mb-2'>
                    <label htmlFor='item_description' className='fw-bold mb-2'>
                      Item Description
                    </label>
                    <div className='d-flex'>
                      <div className='flex-grow-1'>
                        <textarea
                          className={
                            newdata.box_item
                              ? 'form-control form-control-sm form-control-solid'
                              : 'form-control form-control-sm'
                          }
                          name='item_description'
                          id='item_description'
                          rows={1}
                          value={newItem.description || ''}
                          onChange={(e) => {
                            handleItem('description', e.target.value)
                          }}
                          disabled={newdata.box_item}
                        ></textarea>
                      </div>
                      <div className='ms-1'>
                        <button
                          className='btn btn-sm btn-light-success'
                          disabled={newdata.box_item}
                          onClick={UpdateNewItem}
                          title='Update Item Description'
                        >
                          {save}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-3'>
                  <div className='form-group mb-2'>
                    <label htmlFor='qty' className='fw-bold required mb-2'>
                      QTY
                    </label>
                    <input
                      type='text'
                      id='qty'
                      name='qty'
                      maxLength={10}
                      className='form-control form-control-sm'
                      onInput={(e) => {
                        numberOnly(e)
                      }}
                      onChange={(e) => {
                        let input: any = e.target.value
                        handleData('qty', input)
                      }}
                      onBlur={(e) => {
                        let input: any = e.target.value || 0
                        if (input % 1 == 0) {
                          handleData('qty', input)
                        } else {
                          handleData('qty', parseFloat(input).toFixed(3))
                        }
                      }}
                      value={newdata.qty}
                    />
                  </div>
                </div>
                <div className='col-md-3'>
                  <div className='form-group mb-2'>
                    <label htmlFor='unit_id' className='fw-bold required mb-2'>
                      Unit
                    </label>
                    <Select
                      id='unit_id'
                      options={units}
                      onChange={(option: any) => {
                        handleData('unit_id', option.value)
                      }}
                      value={
                        units ? units.find((option: any) => option.value === newdata.unit_id) : ''
                      }
                      styles={customStyles}
                    />
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='form-group mb-2'>
                    <div className='d-flex'>
                      <div className='flex-grow-1'>
                        <label htmlFor='supplier_id' className='fw-bold required mb-2'>
                          Supplier
                        </label>
                        {newdata.box_sup ? (
                          <input
                            type='text'
                            id='supplier_other'
                            className='form-control form-control-sm border border-danger'
                            placeholder='Please fill out'
                            maxLength={255}
                            onChange={(e) => {
                              handleData('supplier_other', e.target.value)
                            }}
                            value={newdata.supplier_other}
                          />
                        ) : (
                          <Select
                            id='supplier_id'
                            options={sups}
                            onChange={(option: any) => {
                              handleData('supplier_id', option.value)
                            }}
                            value={
                              sups
                                ? sups.find((option: any) => option.value === newdata.supplier_id)
                                : ''
                            }
                            styles={customStyles}
                          />
                        )}
                      </div>
                      <div>
                        <label htmlFor='supplier_other' className='fw-bold mb-2'>
                          Other
                        </label>
                        <div className='text-center mt-2'>
                          <input
                            type='checkbox'
                            id='supplier_other'
                            className='form-check-input'
                            checked={newdata.box_sup}
                            onChange={(e) => {
                              handleData('box_sup', e.target.checked)
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-3'>
                  <div className='form-group mb-2'>
                    <label htmlFor='branch_id' className='fw-bold required mb-2'>
                      Branch
                    </label>
                    <Select
                      id='branch_id'
                      options={branches}
                      onChange={(option: any) => {
                        handleData('branch_id', option.value)
                      }}
                      value={
                        branches
                          ? branches.find((option: any) => option.value === newdata.branch_id)
                          : ''
                      }
                      styles={customStyles}
                    />
                  </div>
                </div>
                <div className='col-md-1'>
                  <div className='form-group mb-2'>
                    <label htmlFor='urgent' className='fw-bold mb-2'>
                      Urgent
                    </label>
                    <div className='d-flex justify-content-center mt-xl-2'>
                      <div className='form-check'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='urgent'
                          checked={urgent(newdata.urgency)}
                          onChange={(e) => {
                            handleData('urgency', e.target.checked)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-1'>
                  <div className='form-group mb-2'>
                    <label htmlFor='status' className='fw-bold mb-2'>
                      Status
                    </label>
                    <div className='text-center mt-xl-2'>
                      {statusFormat(newdata.status, newdata)}
                    </div>
                  </div>
                </div>
                <div className='col-md-7'>
                  <div className='form-group mb-2'>
                    <div className='d-flex flex-column'>
                      <label htmlFor='status' className='fw-bold mb-2'>
                        Remark
                      </label>
                      <textarea
                        rows={1}
                        className='form-control form-control-sm'
                        onChange={(e: any) => {
                          handleData('remark', e.target.value)
                        }}
                        value={newdata?.remark}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className='col-lg-12 mt-2'>
                  <h5>User Attachment</h5>
                  <table className='table table-sm table-bordered-2 text-center mt-3'>
                    <thead className='bg-primary text-white'>
                      <tr>
                        <th className='fw-bold align-middle'>File Attach</th>
                        <th className='fw-bold align-middle'>Upload</th>
                        <th className='fw-bold align-middle'>View</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className='align-middle p-1'>Picture(FA)</td>
                        <td className='align-middle p-1'>
                          <Button
                            className='btn btn-secondary btn-sm text-primary'
                            onClick={() => {
                              UploadFiles('pic', setNewData)
                            }}
                            onFocusCapture={() => {
                              BGClose()
                            }}
                          >
                            Upload
                          </Button>
                        </td>
                        <td className='align-middle p-1'>
                          {OpenFile(newdata.file_url_pic, 'pic')}
                        </td>
                      </tr>
                      <tr>
                        <td className='align-middle p-1'>Drawing(FA)</td>
                        <td className='align-middle p-1'>
                          <Button
                            className='btn btn-secondary btn-sm text-primary'
                            onClick={() => {
                              UploadFiles('draw', setNewData)
                            }}
                            onFocusCapture={() => {
                              BGClose()
                            }}
                          >
                            Upload
                          </Button>
                        </td>
                        <td className='align-middle p-1'>
                          {OpenFile(newdata.file_url_draw, 'draw')}
                        </td>
                      </tr>
                      <tr>
                        <td className='align-middle p-1'>Quotation by User</td>
                        <td className='align-middle p-1'>
                          <Button
                            className='btn btn-secondary btn-sm text-primary'
                            onClick={() => {
                              UploadFiles('qs', setNewData)
                            }}
                            onFocusCapture={() => {
                              BGClose()
                            }}
                          >
                            Upload
                          </Button>
                        </td>
                        <td className='align-middle p-1'>
                          {OpenFile(newdata.file_url_qto, 'quotation')}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {remarks?.length > 0 && (
                  <div className='col-lg-12'>
                    <h5>Remark History</h5>
                    <div
                      className='table-responsive'
                      style={remarks.length > 0 ? {maxHeight: '245px'} : {}}
                    >
                      <table className='table table-sm table-bordered-2 text-center mt-3'>
                        <thead className='bg-primary text-white'>
                          <tr>
                            <th className='fw-bold align-middle'>Content</th>
                            <th className='fw-bold align-middle'>User Remark</th>
                            <th className='fw-bold align-middle'>Create Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {remarks?.map((item: any, index: number) => (
                            <tr key={index}>
                              <td>{item?.content}</td>
                              <td>{item?.firstnameEn + ' ' + item?.lastnameEn}</td>
                              <td>{CoverTime4(item.created_at)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
                <div className='col-lg-12'>
                  {quotationHistories?.length > 0 && (
                    <div className='mb-2'>
                      <h5>Updated History</h5>
                      <div
                        className='table-responsive'
                        style={remarks.length > 0 ? {maxHeight: '245px'} : {}}
                      >
                        <table className='table table-sm table-bordered-2 text-center'>
                          <thead className='bg-primary text-white'>
                            <tr>
                              <th className='fw-bold align-middle'>Status</th>
                              <th className='fw-bold align-middle'>Update By</th>
                              <th className='fw-bold align-middle'>Update Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {quotationHistories?.map((item: any, index: number) => (
                              <tr key={index}>
                                <td>{item?.status}</td>
                                <td>{item?.firstnameEn + ' ' + item?.lastnameEn}</td>
                                <td>{CoverTime4(item.created_at)}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='col-md-2'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='form-group mb-2'>
                    <label htmlFor='' className='fw-bold mb-2'>
                      Create Date {calendar}
                    </label>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      value={CovertTime(newdata.created_at)}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='form-group mb-2'>
                    <label htmlFor='' className='fw-bold mb-2'>
                      Update Date {calendar}
                    </label>
                    <input
                      type='text'
                      className='form-control form-control-sm'
                      value={CovertTime(newdata.updated_at)}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className='col-md-12'>
                <div className='form-group mb-2'>
                  <label htmlFor='' className='fw-bold required mb-2'>
                    Required Date {calendar}
                  </label>
                  <DatePicker
                    className='form-control form-control-sm'
                    selected={new Date(newdata.require_date)}
                    onChange={(date: any) => {
                      handleData('require_date', date)
                    }}
                    dateFormat='yyyy/MM/dd'
                    maxDate={new Date('12-31-9999')}
                    placeholderText='yyyy/mm/dd'
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='primary'
            onClick={() => {
              submit()
            }}
          >
            {save}
            Save
          </Button>
          <Button variant='danger' onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export {QSEdit}
