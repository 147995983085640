import {useState, useEffect} from 'react'
import {Modal, Button, Form} from 'react-bootstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlus} from '@fortawesome/free-solid-svg-icons'
import {QSVerifyGroupList, QSVerifyAddGroup} from '../../../api/requests/QuotationRequest'
import Select from 'react-select'
import Swal from 'sweetalert2'
import {customStyles} from '../../Styles/ReactSelect'

const VerifyAddGroup = ({data, resetData}: any) => {
  const [qs, setQS] = useState([])
  const [show, setShow] = useState(false)
  const [jobNo, setJobNo] = useState(0)
  const [id, setID] = useState([])

  const handleClose = () => {
    setJobNo(0)
    setID([])
    setShow(false)
  }

  const handleShow = () => {
    if (data.length > 0) {
      getGroup()
      setShow(true)
    } else {
      Swal.fire({
        title: 'Please select the data add in group.',
        icon: 'error',
        heightAuto: false,
        returnFocus: false,
      })
    }
  }

  const getGroup = async () => {
    let options: any = []
    const res: any = await QSVerifyGroupList()
    if (res.data.data.length > 0) {
      res.data.data.map((val: any) => {
        options.push({value: val.job_no, label: 'GP no: ' + val.job_number})
      })
      setQS(options)
    }
  }

  const getAddGroup = (option: any) => {
    setJobNo(option.value)
    setID(data)
  }

  const addGroup = () => {
    if (typeof jobNo !== 'undefined' && id.length > 0 && typeof id !== 'undefined') {
      Swal.fire({
        title: 'Are you confirm to add in group?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        heightAuto: false,
        returnFocus: false,
        showLoaderOnConfirm: true,
        preConfirm: (e) => {
          return new Promise(async function (resolve) {
            await QSVerifyAddGroup(id, jobNo)
              .then((res) => {
                if (res.data.status == true) {
                  Swal.fire({
                    title: 'Successful add in group!',
                    icon: 'success',
                    showConfirmButton: false,
                    heightAuto: false,
                    timer: 1500,
                    returnFocus: false,
                  }).then(() => {
                    handleClose()
                    resetData()
                  })
                } else {
                  Swal.fire({
                    title: res.data.message,
                    icon: 'error',
                    heightAuto: false,
                    returnFocus: false,
                  })
                }
              })
              .catch((err) => {
                Swal.fire({
                  title: 'Failed to add',
                  icon: 'error',
                  heightAuto: false,
                  returnFocus: false,
                })
              })
          })
        },
      })
    } else {
      Swal.fire({
        title: 'No select information found.',
        text: 'Please select information first.',
        icon: 'error',
        heightAuto: false,
        returnFocus: false,
      })
    }
  }

  useEffect(() => {}, [id, jobNo])

  return (
    <>
      <button
        className='btn btn-light-info me-2'
        onClick={() => {
          handleShow()
        }}
      >
        <FontAwesomeIcon icon={faPlus} className='fa-fw' />
        &nbsp;Add&nbsp;exist&nbsp;group
      </button>
      <Modal show={show} onHide={handleClose} size='lg' centered>
        <Modal.Header closeButton>
          <Modal.Title>Select Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='col-md-10'>
              <Select
                options={qs}
                onChange={(option: any) => {
                  getAddGroup(option)
                }}
                placeholder={'Select group'}
                styles={customStyles}
              />
            </div>
            <div className='col-md-2'>
              <button
                className='btn btn-light-info btn-sm'
                onClick={() => {
                  addGroup()
                }}
              >
                Select
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export {VerifyAddGroup}
