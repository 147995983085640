import {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import Swal from 'sweetalert2'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useNavigate} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSave, faXmark, faPlus, faMinus, faCircle, faL} from '@fortawesome/free-solid-svg-icons'
import * as PATH from '../../../modules/path/Path'
import {PermissionList} from '../../../modules/api/requests/PermissionRequest'
import {RoleStore} from '../../../modules/api/requests/RoleRequest'
import {useCurrentPage} from '../HistoryPage'

const RoleForm = () => {
  const navigate = useNavigate()
  const [syssub, setSysSub] = useState(false)
  const [qssub, setQSSub] = useState(false)
  const {currentPage, setCurrentPage, currentPages} = useCurrentPage()

  let qs_group = ['qs_create', 'qs_pending', 'qs_verify', 'qs_report', 'qs_cancel']
  let system_data_group = [
    'user_management',
    'role',
    'department',
    'branch',
    'supplier_group',
    'supplier',
    'item_group',
    'item',
    'unit',
    'document_concerned',
  ]

  const [options, setOptions] = useState([
    {id: 0, guard_name: 'system_data', status: false},
    {id: 0, guard_name: 'quotation', status: false},
    {id: 0, guard_name: 'user_management', status: false},
    {id: 0, guard_name: 'role', status: false},
    {id: 0, guard_name: 'department', status: false},
    {id: 0, guard_name: 'branch', status: false},
    {id: 0, guard_name: 'supplier_group', status: false},
    {id: 0, guard_name: 'supplier', status: false},
    {id: 0, guard_name: 'item_group', status: false},
    {id: 0, guard_name: 'item', status: false},
    {id: 0, guard_name: 'unit', status: false},
    {id: 0, guard_name: 'document_concerned', status: false},
    {id: 0, guard_name: 'qs_create', status: false},
    {id: 0, guard_name: 'qs_pending', status: false},
    {id: 0, guard_name: 'qs_verify', status: false},
    {id: 0, guard_name: 'qs_report', status: false},
    {id: 0, guard_name: 'qs_cancel', status: false},
    {id: 0, guard_name: 'log', status: false},
  ])

  const plusIcon = <FontAwesomeIcon icon={faPlus} className='fa-fw fa-sm' />
  const minusIcon = <FontAwesomeIcon icon={faMinus} className='fa-fw fa-sm' />
  const circleIcon = <FontAwesomeIcon icon={faCircle} className='fa-fw fa-xs' />

  const getPermission = async () => {
    let option_new: any = [
      {id: 0, guard_name: 'system_data', status: false},
      {id: 0, guard_name: 'quotation', status: false},
    ]
    const res: any = await PermissionList()
    const data: any = res.data.data
    data.map((val: any) => {
      option_new.push({id: val.id, guard_name: val.guard_name, status: false})
    })
    setOptions(option_new)
  }

  const openSysSup = () => {
    if (syssub === true) {
      setSysSub(false)
    } else {
      setSysSub(true)
    }
  }

  const openQSSup = () => {
    if (qssub === true) {
      setQSSub(false)
    } else {
      setQSSub(true)
    }
  }

  const recheckAll = () => {
    let sd = options
      .filter((item: any) => {
        if (system_data_group.includes(item.guard_name) && item.status == true) {
          return item
        }
      })
      .map((item: any) => item.status)

    if (sd.length == 10) {
      let equalsd = sd.every((val: any) => val === true)
      if (equalsd) {
        setOptions((options) =>
          options.map((val: any) => {
            if (val.guard_name == 'system_data') {
              val.status = true
            }
            return val
          })
        )
      } else {
        setOptions((options) =>
          options.map((val: any) => {
            if (val.guard_name == 'system_data') {
              val.status = false
            }
            return val
          })
        )
      }
    } else {
      setOptions((options) =>
        options.map((val: any) => {
          if (val.guard_name == 'system_data') {
            val.status = false
          }
          return val
        })
      )
    }

    let qs = options
      .filter((item: any) => {
        if (qs_group.includes(item.guard_name) && item.status == true) {
          return item
        }
      })
      .map((item: any) => item.status)

    if (qs.length == 5) {
      let equalqs = qs.every((val: any) => val === true)
      if (equalqs) {
        setOptions((options) =>
          options.map((val: any) => {
            if (val.guard_name == 'quotation') {
              val.status = true
            }
            return val
          })
        )
      } else {
        setOptions((options) =>
          options.map((val: any) => {
            if (val.guard_name == 'quotation') {
              val.status = false
            }
            return val
          })
        )
      }
    } else {
      setOptions((options) =>
        options.map((val: any) => {
          if (val.guard_name == 'quotation') {
            val.status = false
          }
          return val
        })
      )
    }
  }

  const handleOption = async (sys: string, value: any) => {
    if (sys == 'system_data') {
      setOptions((options) =>
        options.map((val: any) => {
          if (
            val.guard_name == 'system_data' ||
            val.guard_name == 'user_management' ||
            val.guard_name == 'role' ||
            val.guard_name == 'department' ||
            val.guard_name == 'branch' ||
            val.guard_name == 'supplier_group' ||
            val.guard_name == 'supplier' ||
            val.guard_name == 'item_group' ||
            val.guard_name == 'item' ||
            val.guard_name == 'unit' ||
            val.guard_name == 'document_concerned'
          ) {
            val.status = value
          }
          return val
        })
      )
    } else if (sys === 'quotation') {
      setOptions((options) =>
        options.map((val: any) => {
          if (
            val.guard_name == 'quotation' ||
            val.guard_name == 'qs_create' ||
            val.guard_name == 'qs_pending' ||
            val.guard_name == 'qs_verify' ||
            val.guard_name == 'qs_report' ||
            val.guard_name == 'qs_cancel'
          ) {
            val.status = value
          }
          return val
        })
      )
    } else {
      if (Object.values(system_data_group).includes(sys)) {
        await setOptions((options) =>
          options.map((val: any) => {
            if (val.guard_name == 'system_data') {
              val.status = false
            }
            if (val.guard_name == sys) {
              val.status = value
            }
            return val
          })
        )
        recheckAll()
      } else if (Object.values(qs_group).includes(sys)) {
        await setOptions((options) =>
          options.map((val: any) => {
            if (val.guard_name == 'quotation') {
              val.status = false
            }
            if (val.guard_name == sys) {
              val.status = value
            }
            return val
          })
        )
        recheckAll()
      } else {
        setOptions((options) =>
          options.map((val: any) => {
            if (val.guard_name == sys) {
              val.status = value
            }
            return val
          })
        )
      }
    }
  }

  const comeback = () => {
    let topic: any = ['system_data', 'quotation']
    let newop = options
      .filter((item) => {
        if (!topic.includes(item.guard_name)) {
          return item
        }
      })
      .map((item) => item.status)
    if (newop.length > 0) {
      let equal = newop.every((val) => val === false)
      if (equal) {
        navigate(PATH.ROLE)
      } else {
        Swal.fire({
          title: 'Are you confirm to cancel?',
          icon: 'info',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          heightAuto: false,
          returnFocus: false,
        }).then(async (result) => {
          if (result.isConfirmed) {
            navigate(PATH.ROLE)
          }
        })
      }
    } else {
      navigate(PATH.ROLE)
    }
  }

  const IsEmty = () => {
    let option: any = options
      .filter((val: any) => val.status === true && val.id != 0)
      .map((val: any) => val.id)
    let str = ''

    if (!formik.values.role_name) {
      str += 'Please enter role name'
    }

    if (option.length <= 0) {
      if (str != '') {
        str += ','
      }
      str += 'Please set permissions'
    }

    if (str != '') {
      Swal.fire({
        title: str,
        icon: 'error',
        confirmButtonColor: '#3085d6',
        heightAuto: false,
        returnFocus: false,
      })
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      role_name: '',
    },
    validationSchema: Yup.object({
      role_name: Yup.string()
        .max(255, 'Must be 255 characters or less')
        .required('role name is required'),
    }),
    onSubmit: async (values) => {
      let option: any = options
        .filter((val: any) => val.status === true && val.id != 0)
        .map((val: any) => val.id)
      if (option.length > 0) {
        Swal.fire({
          title: 'Are you confirm to save?',
          icon: 'info',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          heightAuto: false,
          returnFocus: false,
          showLoaderOnConfirm: true,
          preConfirm: (e) => {
            return new Promise(async function (resolve) {
              await RoleStore(values.role_name, option)
                .then((res) => {
                  if (res.data.status == true) {
                    Swal.fire({
                      title: 'Successful created!',
                      icon: 'success',
                      showConfirmButton: false,
                      heightAuto: false,
                      timer: 1500,
                      returnFocus: false,
                    }).then(() => {
                      navigate(PATH.ROLE)
                    })
                  } else {
                    Swal.fire({
                      title: res.data.message,
                      icon: 'error',
                      heightAuto: false,
                      returnFocus: false,
                    })
                  }
                })
                .catch((err) => {
                  Swal.fire({
                    title: 'Failed to save',
                    icon: 'error',
                    heightAuto: false,
                    returnFocus: false,
                  })
                })
            })
          },
        })
      }
    },
  })

  const getPage = () => {
    if (currentPages) {
      currentPages.page = 'roles'
      currentPages.status = false
    }
  }

  useEffect(() => {
    getPage()
    getPermission()
  }, [])

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className='card'>
          <div className='card-header'>
            <div className='card-title'>
              <h3>Role - Create</h3>
            </div>
            <div className='card-toolbar'>
              <button
                type='button'
                className='btn btn-danger shadow-sm me-2'
                onClick={() => {
                  comeback()
                }}
              >
                <FontAwesomeIcon icon={faXmark} className='fa-fw' />
                Cancel
              </button>
              <button type='submit' className='btn btn-primary shadow-sm' onClick={IsEmty}>
                <FontAwesomeIcon icon={faSave} className='fa-fw' />
                Save
              </button>
            </div>
          </div>
          <div className='card-body'>
            <div className='row'>
              {/* role */}
              <div className='col-md-6 border-right mb-2'>
                <div className='form-group'>
                  <label htmlFor='role_name' className='fw-bold required mb-2'>
                    Role Name
                  </label>
                  <input
                    type='text'
                    id='role_name'
                    name='role_name'
                    className={'form-control form-control-sm'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.role_name && formik.errors.role_name ? (
                    <div className='text-danger'>{formik.errors.role_name}</div>
                  ) : null}
                </div>
              </div>
              {/* permission */}
              <div className='col-md-6'>
                <label htmlFor='role_name' className='fw-bold mb-2'>
                  Permissions
                </label>
                <table className='table table-sm table-bordered-2 td-p-1'>
                  <thead className='text-center text-white bg-primary'>
                    <tr>
                      <th>System</th>
                      <th>Option</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* system data */}
                    <tr className='bg-light-primary'>
                      <td
                        className='ps-2'
                        onClick={() => {
                          openSysSup()
                        }}
                      >
                        System data
                        {syssub === false ? plusIcon : minusIcon}
                      </td>
                      <td>
                        <div className='d-flex justify-content-center'>
                          <div className='form-check me-2'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              id='system_data'
                              checked={
                                options.length > 0
                                  ? options.find((val: any) => val.guard_name == 'system_data')
                                      ?.status
                                  : false
                              }
                              onChange={(e) => {
                                handleOption('system_data', e.target.checked)
                              }}
                            />
                            <label className='form-check-label' htmlFor='system_data'>
                              allow
                            </label>
                          </div>
                        </div>
                      </td>
                    </tr>
                    {syssub === true ? (
                      <>
                        <tr>
                          <td className='ps-2'> - User Management</td>
                          <td>
                            <div className='d-flex justify-content-center'>
                              <div className='form-check me-2'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  id='user_management'
                                  checked={
                                    options.length > 0
                                      ? options.find(
                                          (val: any) => val.guard_name == 'user_management'
                                        )?.status
                                      : false
                                  }
                                  onChange={(e) => {
                                    handleOption('user_management', e.target.checked)
                                  }}
                                />
                                <label className='form-check-label' htmlFor='user_management'>
                                  allow
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className='ps-2'> - Role</td>
                          <td>
                            <div className='d-flex justify-content-center'>
                              <div className='form-check me-2'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  value=''
                                  id='role'
                                  checked={
                                    options.length > 0
                                      ? options.find((val: any) => val.guard_name == 'role')?.status
                                      : false
                                  }
                                  onChange={(e) => {
                                    handleOption('role', e.target.checked)
                                  }}
                                />
                                <label className='form-check-label' htmlFor='role'>
                                  allow
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className='ps-2'> - Department</td>
                          <td>
                            <div className='d-flex justify-content-center'>
                              <div className='form-check me-2'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  value=''
                                  id='department'
                                  checked={
                                    options.length > 0
                                      ? options.find((val: any) => val.guard_name == 'department')
                                          ?.status
                                      : false
                                  }
                                  onChange={(e) => {
                                    handleOption('department', e.target.checked)
                                  }}
                                />
                                <label className='form-check-label' htmlFor='department'>
                                  allow
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className='ps-2'> - Supplier Group</td>
                          <td>
                            <div className='d-flex justify-content-center'>
                              <div className='form-check me-2'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  value=''
                                  id='supplier_group'
                                  checked={
                                    options.length > 0
                                      ? options.find(
                                          (val: any) => val.guard_name == 'supplier_group'
                                        )?.status
                                      : false
                                  }
                                  onChange={(e) => {
                                    handleOption('supplier_group', e.target.checked)
                                  }}
                                />
                                <label className='form-check-label' htmlFor='supplier_group'>
                                  allow
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className='ps-2'> - Supplier</td>
                          <td>
                            <div className='d-flex justify-content-center'>
                              <div className='form-check me-2'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  value=''
                                  id='supplier'
                                  checked={
                                    options.length > 0
                                      ? options.find((val: any) => val.guard_name == 'supplier')
                                          ?.status
                                      : false
                                  }
                                  onChange={(e) => {
                                    handleOption('supplier', e.target.checked)
                                  }}
                                />
                                <label className='form-check-label' htmlFor='supplier'>
                                  allow
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className='ps-2'> - Branch</td>
                          <td>
                            <div className='d-flex justify-content-center'>
                              <div className='form-check me-2'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  value=''
                                  id='branch'
                                  checked={
                                    options.length > 0
                                      ? options.find((val: any) => val.guard_name == 'branch')
                                          ?.status
                                      : false
                                  }
                                  onChange={(e) => {
                                    handleOption('branch', e.target.checked)
                                  }}
                                />
                                <label className='form-check-label' htmlFor='branch'>
                                  allow
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className='ps-2'> - Item Group</td>
                          <td>
                            <div className='d-flex justify-content-center'>
                              <div className='form-check me-2'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  value=''
                                  id='item_group'
                                  checked={
                                    options.length > 0
                                      ? options.find((val: any) => val.guard_name == 'item_group')
                                          ?.status
                                      : false
                                  }
                                  onChange={(e) => {
                                    handleOption('item_group', e.target.checked)
                                  }}
                                />
                                <label className='form-check-label' htmlFor='item_group'>
                                  allow
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className='ps-2'> - Item</td>
                          <td>
                            <div className='d-flex justify-content-center'>
                              <div className='form-check me-2'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  value=''
                                  id='item'
                                  checked={
                                    options.length > 0
                                      ? options.find((val: any) => val.guard_name == 'item')?.status
                                      : false
                                  }
                                  onChange={(e) => {
                                    handleOption('item', e.target.checked)
                                  }}
                                />
                                <label className='form-check-label' htmlFor='item'>
                                  allow
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className='ps-2'> - Units</td>
                          <td>
                            <div className='d-flex justify-content-center'>
                              <div className='form-check me-2'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  value=''
                                  id='unit'
                                  checked={
                                    options.length > 0
                                      ? options.find((val: any) => val.guard_name == 'unit')?.status
                                      : false
                                  }
                                  onChange={(e) => {
                                    handleOption('unit', e.target.checked)
                                  }}
                                />
                                <label className='form-check-label' htmlFor='unit'>
                                  allow
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className='ps-2'> - Document Concerned</td>
                          <td>
                            <div className='d-flex justify-content-center'>
                              <div className='form-check me-2'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  value=''
                                  id='document_concerned'
                                  checked={
                                    options.length > 0
                                      ? options.find(
                                          (val: any) => val.guard_name == 'document_concerned'
                                        )?.status
                                      : false
                                  }
                                  onChange={(e) => {
                                    handleOption('document_concerned', e.target.checked)
                                  }}
                                />
                                <label className='form-check-label' htmlFor='document_concerned'>
                                  allow
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </>
                    ) : (
                      ''
                    )}
                    {/* quotation */}
                    <tr className='bg-light-primary'>
                      <td
                        className='ps-2'
                        onClick={() => {
                          openQSSup()
                        }}
                      >
                        Quotation
                        {qssub === false ? plusIcon : minusIcon}
                      </td>
                      <td>
                        <div className='d-flex justify-content-center'>
                          <div className='form-check me-2'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              value=''
                              id='quotation'
                              checked={
                                options.length > 0
                                  ? options.find((val: any) => val.guard_name == 'quotation')
                                      ?.status
                                  : false
                              }
                              onChange={(e) => {
                                handleOption('quotation', e.target.checked)
                              }}
                            />
                            <label className='form-check-label' htmlFor='quotation'>
                              allow
                            </label>
                          </div>
                        </div>
                      </td>
                    </tr>
                    {qssub === true ? (
                      <>
                        <tr>
                          <td className='ps-2'> - Create</td>
                          <td>
                            <div className='d-flex justify-content-center'>
                              <div className='form-check me-2'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  value=''
                                  id='qs_create'
                                  checked={
                                    options.length > 0
                                      ? options.find((val: any) => val.guard_name == 'qs_create')
                                          ?.status
                                      : false
                                  }
                                  onChange={(e) => {
                                    handleOption('qs_create', e.target.checked)
                                  }}
                                />
                                <label className='form-check-label' htmlFor='qs_create'>
                                  allow
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className='ps-2'> - Pending List</td>
                          <td>
                            <div className='d-flex justify-content-center'>
                              <div className='form-check me-2'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  value=''
                                  id='qs_pending'
                                  checked={
                                    options.length > 0
                                      ? options.find((val: any) => val.guard_name == 'qs_pending')
                                          ?.status
                                      : false
                                  }
                                  onChange={(e) => {
                                    handleOption('qs_pending', e.target.checked)
                                  }}
                                />
                                <label className='form-check-label' htmlFor='qs_pending'>
                                  allow
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className='ps-2'> - Verification List</td>
                          <td>
                            <div className='d-flex justify-content-center'>
                              <div className='form-check me-2'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  value=''
                                  id='qs_verify'
                                  checked={
                                    options.length > 0
                                      ? options.find((val: any) => val.guard_name == 'qs_verify')
                                          ?.status
                                      : false
                                  }
                                  onChange={(e) => {
                                    handleOption('qs_verify', e.target.checked)
                                  }}
                                />
                                <label className='form-check-label' htmlFor='qs_verify'>
                                  allow
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className='ps-2'> - Report List</td>
                          <td>
                            <div className='d-flex justify-content-center'>
                              <div className='form-check me-2'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  value=''
                                  id='qs_report'
                                  checked={
                                    options.length > 0
                                      ? options.find((val: any) => val.guard_name == 'qs_report')
                                          ?.status
                                      : false
                                  }
                                  onChange={(e) => {
                                    handleOption('qs_report', e.target.checked)
                                  }}
                                />
                                <label className='form-check-label' htmlFor='qs_report'>
                                  allow
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className='ps-2'> - Cancel List</td>
                          <td>
                            <div className='d-flex justify-content-center'>
                              <div className='form-check me-2'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  value=''
                                  id='qs_cancel'
                                  checked={
                                    options.length > 0
                                      ? options.find((val: any) => val.guard_name == 'qs_cancel')
                                          ?.status
                                      : false
                                  }
                                  onChange={(e) => {
                                    handleOption('qs_cancel', e.target.checked)
                                  }}
                                />
                                <label className='form-check-label' htmlFor='qs_cancel'>
                                  allow
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </>
                    ) : (
                      ''
                    )}
                    {/* log */}
                    <tr className='bg-light-primary'>
                      <td className='ps-2'>Logs</td>
                      <td>
                        <div className='d-flex justify-content-center'>
                          <div className='form-check me-2'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              value=''
                              id='log'
                              checked={
                                options.length > 0
                                  ? options.find((val: any) => val.guard_name == 'log')?.status
                                  : false
                              }
                              onChange={(e) => {
                                handleOption('log', e.target.checked)
                              }}
                            />
                            <label className='form-check-label' htmlFor='log'>
                              allow
                            </label>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

const breadcrumbs = [
  {
    title: 'Home',
    path: '/',
    isActive: false,
  },
  {
    title: '',
    path: '',
    isActive: false,
    isSeparator: true,
  },
  {
    title: 'Role',
    path: PATH.ROLE,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isActive: false,
    isSeparator: true,
  },
]

const RoleCreate = () => {
  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>Role - Create</PageTitle>
      <RoleForm />
    </>
  )
}

export default RoleCreate
