// <<------- home ------->>

export const HOME = '/home'

// <<------- system data ------->>

// department
export const DEPT_LIST = '/system-data/department/list'
export const DEPT_CREATE = '/system-data/department/create'
export const DEPT_EDIT = '/system-data/department/edit/'

// branch
export const BRANCH_LIST = '/system-data/branch/list'
export const BRANCH_CREATE = '/system-data/branch/create'
export const BRANCH_EDIT = '/system-data/branch/edit/'

// unit
export const UNIT_LIST = '/system-data/unit/list'
export const UNIT_CREATE = '/system-data/unit/create'
export const UNIT_EDIT = '/system-data/unit/edit/'

// document concerned
export const DC_LIST = '/system-data/document-concerned/list'
export const DC_CREATE = '/system-data/document-concerned/create'
export const DC_EDIT = '/system-data/document-concerned/edit'

// item group
export const IG_LIST = '/system-data/item-group/list'
export const IG_CREATE = '/system-data/item-group/create'
export const IG_EDIT = '/system-data/item-group/edit/'

// item
export const ITEM_LIST = '/system-data/item/list'
export const ITEM_CREATE = '/system-data/item/create'
export const ITEM_EDIT = '/system-data/item/edit/'

// supplier group
export const SG_LIST = '/system-data/supplier-group/list'
export const SG_CREATE = '/system-data/supplier-group/create'
export const SG_EDIT = '/system-data/supplier-group/edit/'

// supplier
export const SUP_LIST = '/system-data/supplier/list'
export const SUP_CREATE = '/system-data/supplier/create'
export const SUP_EDIT = '/system-data/supplier/edit/'

// user and role
export const USER_LIST = '/system-data/user'
export const ROLE = '/system-data/role/list'
export const ROLE_CREATE = '/system-data/role/create'
export const ROLE_EDIT = '/system-data/role/edit/'

// <<------- log ------->>

export const LOG = '/logs'

// <<------- quotation ------->>

export const QS_LIST = '/quotation/list'
export const QS_CREATE = '/quotation/create'
export const QS_PENDING = '/quotation/pending-list'
export const QS_VERIFY = '/quotation/verification-list'
export const QS_CANCEL = '/quotation/cancel'
