import {useState, useEffect} from 'react'
import {Modal, Button} from 'react-bootstrap'
import Swal from 'sweetalert2'
import {useAuth} from '../../../auth'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faClipboardCheck,
  faUpRightAndDownLeftFromCenter,
  faDownLeftAndUpRightToCenter,
  faCheck,
  faRotateLeft,
} from '@fortawesome/free-solid-svg-icons'
import {CovertTime} from '../../func/CoverTime'
import {OpenFile} from '../../func/OpenFile'
import {QSPendingApprove} from '../../../api/requests/QuotationRequest'
import {SendReturnRequirement} from '../../../api/requests/MailRequest'

const PendingShow = ({data, clearData}: any) => {
  const [show, setShow] = useState(false)
  const [fullscreen, setFullscreen] = useState<any>('')
  const {currentUser}: any = useAuth()

  let requisition_date: any = CovertTime(data.created_at) || '-'

  const handleClose = () => {
    clearData()
    setShow(false)
  }
  const handleShow = () => setShow(true)

  const openFull = () => {
    if (fullscreen === true) {
      setFullscreen('')
    } else {
      setFullscreen(true)
    }
  }

  const handleComment = (value: any) => {
    data.gp_verify_comment = value
  }

  const handleRemark = (value: any) => {
    data.remark_content = value
  }

  const submit = (type: string) => {
    let statas: boolean = true
    if (
      type == 'return' &&
      (data.gp_verify_comment == '' ||
        typeof data.gp_verify_comment === 'undefined' ||
        data.gp_verify_comment === null)
    ) {
      statas = false
    }

    if (statas) {
      Swal.fire({
        title: 'Are you confirm to save?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        heightAuto: false,
        returnFocus: false,
        showLoaderOnConfirm: true,
        preConfirm: (e) => {
          return new Promise(async function (resolve) {
            await QSPendingApprove(data.id, type, currentUser.id, data.gp_verify_comment)
              .then(async (res) => {
                if (res.data.status === true) {
                  if (type == 'return') {
                    await SendReturnRequirement([data?.id])
                  }
                  Swal.fire({
                    title: 'Successful created!',
                    icon: 'success',
                    showConfirmButton: false,
                    heightAuto: false,
                    timer: 1500,
                    returnFocus: false,
                  }).then(() => {
                    handleClose()
                  })
                } else {
                  Swal.fire({
                    title: res.data.message,
                    text: '',
                    icon: 'error',
                    heightAuto: false,
                    returnFocus: false,
                  })
                }
              })
              .catch((err) => {
                console.log(err)
                Swal.fire({
                  title: 'Failed to save',
                  text: '',
                  icon: 'error',
                  heightAuto: false,
                  returnFocus: false,
                })
              })
          })
        },
      })
    } else {
      Swal.fire({
        title: 'Please enter a reason if you wish to return.',
        icon: 'error',
        heightAuto: false,
        returnFocus: false,
      })
    }
  }

  return (
    <>
      <button
        type='button'
        className='btn btn-light-success btn-sm'
        onClick={() => {
          handleShow()
        }}
      >
        <FontAwesomeIcon icon={faClipboardCheck} className='fa-fw' />
      </button>
      <Modal show={show} onHide={handleClose} size='xl' fullscreen={fullscreen} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <div>Pending List</div>
          </Modal.Title>
          <button
            className='btn btn-light-white btn-sm'
            onClick={() => {
              openFull()
            }}
          >
            {fullscreen != true ? (
              <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} className='fa-fw' />
            ) : (
              <FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} className='fa-fw' />
            )}
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            {/* form user */}
            <div className='col-md-8 border-right mb-2'>
              <h2>Form User</h2>
              <div className='row'>
                <div className='col-md-4'>
                  <div className='form-group mb-2'>
                    <label htmlFor='req_date' className='fw-bold mb-2'>
                      Requisition Date
                    </label>
                    <input
                      type='text'
                      id='req_date'
                      className='form-control form-control-sm form-control-solid'
                      value={requisition_date}
                      readOnly
                    />
                  </div>
                </div>
                <div className='col-md-8'>
                  <div className='form-group mb-2'>
                    <label htmlFor='requestor' className='fw-bold mb-2'>
                      Requestor
                    </label>
                    <textarea
                      id='requestor'
                      rows={1}
                      className='form-control form-control-sm form-control-solid'
                      value={data.requestor_name + ' ' + data.requestor_lastname || '-'}
                      readOnly
                    ></textarea>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='form-group mb-2'>
                    <label htmlFor='sect' className='fw-bold mb-2'>
                      Section/Department
                    </label>
                    <textarea
                      id='sect'
                      rows={1}
                      className='form-control form-control-sm form-control-solid'
                      value={data.dept_name || '-'}
                      readOnly
                    ></textarea>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='form-group mb-2'>
                    <label htmlFor='for_dept' className='fw-bold mb-2'>
                      For Dept
                    </label>
                    <textarea
                      id='for_dept'
                      rows={1}
                      className='form-control form-control-sm form-control-solid'
                      value={data.for_dept_name || '-'}
                      readOnly
                    ></textarea>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='form-group mb-2'>
                    <label htmlFor='item_group' className='fw-bold mb-2'>
                      Group Item
                    </label>
                    <textarea
                      id='item_group'
                      rows={1}
                      className='form-control form-control-sm form-control-solid'
                      value={data.item_group_title || '-'}
                      readOnly
                    ></textarea>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='form-group mb-2'>
                    <label htmlFor='item' className='fw-bold mb-2'>
                      Item
                    </label>
                    <textarea
                      id='item'
                      rows={1}
                      className='form-control form-control-sm form-control-solid'
                      value={data.item_title || data.item_other || '-'}
                      readOnly
                    ></textarea>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='form-group mb-2'>
                    <label htmlFor='item_des' className='fw-bold mb-2'>
                      Item Description
                    </label>
                    <textarea
                      id='item_des'
                      rows={1}
                      className='form-control form-control-sm form-control-solid'
                      value={data.item_description || '-'}
                      readOnly
                    ></textarea>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='form-group mb-2'>
                    <label htmlFor='qty' className='fw-bold mb-2'>
                      QTY
                    </label>
                    <input
                      type='text'
                      id='qty'
                      className='form-control form-control-sm form-control-solid'
                      value={data.qty || 0}
                      readOnly
                    />
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='form-group mb-2'>
                    <label htmlFor='unit' className='fw-bold mb-2'>
                      Unit
                    </label>
                    <textarea
                      id='unit'
                      rows={1}
                      className='form-control form-control-sm form-control-solid'
                      value={data.unit_title || '-'}
                      readOnly
                    ></textarea>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='form-group mb-2'>
                    <label htmlFor='branch' className='fw-bold mb-2'>
                      Branch
                    </label>
                    <textarea
                      id='branch'
                      rows={1}
                      className='form-control form-control-sm form-control-solid'
                      value={data.branch_title || '-'}
                      readOnly
                    ></textarea>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='form-group mb-2'>
                    <label htmlFor='supplier' className='fw-bold mb-2'>
                      Supplier
                    </label>
                    <textarea
                      id='supplier'
                      rows={1}
                      className='form-control form-control-sm form-control-solid'
                      value={data.supplier_title || data.supplier_other || '-'}
                      readOnly
                    ></textarea>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='form-group mb-2'>
                    <table className='table table-sm table-bordered-2 text-center'>
                      <thead className='bg-primary text-white'>
                        <tr>
                          <th className='text-white min-w-100px fw-bold'>Picture(FA)</th>
                          <th className='text-white min-w-100px fw-bold'>Drawing(FA)</th>
                          <th className='text-white min-w-100px fw-bold'>Quotation by User</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{OpenFile(data.file_url_pic, 'pic')}</td>
                          <td>{OpenFile(data.file_url_draw, 'draw')}</td>
                          <td>{OpenFile(data.file_url_qto, 'quotation')}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/* form system */}
            <div className='col-md-4 mb-2'>
              <div className='form-system'>
                <h2>Form System</h2>
                <div className='form-group mb-2'>
                  <label htmlFor='sup_sys' className='fw-bold mb-2'>
                    Supplier
                  </label>
                  <textarea
                    id='sup_sys'
                    rows={1}
                    className='form-control form-control-sm form-control-solid'
                    value={data.form_system_sup || '-'}
                    readOnly
                  ></textarea>
                </div>
                <div className='form-group mb-2'>
                  <label htmlFor='sg_sys' className='fw-bold mb-2'>
                    Supplier Group
                  </label>
                  <textarea
                    id='sg_sys'
                    rows={1}
                    className='form-control form-control-sm form-control-solid'
                    value={data.form_system_sg || '-'}
                    readOnly
                  ></textarea>
                </div>
                <div className='form-group mb-2'>
                  <label htmlFor='price_sys' className='fw-bold mb-2'>
                    Price
                  </label>
                  <input
                    type='text'
                    id='price_sys'
                    className='form-control form-control-sm form-control-solid'
                    value={data.form_system_price || 0}
                    readOnly
                  />
                </div>
                <div className='form-group mb-2'>
                  <label htmlFor='unit_sys' className='fw-bold mb-2'>
                    Unit
                  </label>
                  <input
                    type='text'
                    id='unit_sys'
                    className='form-control form-control-sm form-control-solid'
                    value={data.form_system_unit || '-'}
                    readOnly
                  />
                </div>
                <div className='form-group mb-2'>
                  <label htmlFor='total_sys' className='fw-bold mb-2'>
                    Total
                  </label>
                  <input
                    type='text'
                    id='total_sys'
                    className='form-control form-control-sm form-control-solid'
                    value={(data.form_system_qty || 0) * (data.form_system_price || 0)}
                    readOnly
                  />
                </div>
              </div>
            </div>
            {/* GP Verify */}
            <div className='col-md-12'>
              <hr />
              <div className='row'>
                <div className='col-md-6'>
                  <div className='form-group mb-2'>
                    <div className='d-flex flex-column'>
                      <div className='d-flex justify-content-between flex-shrink-0 align-items-end'>
                        <label htmlFor='PendingListRemark'>Remark</label>
                      </div>
                      <textarea
                        id='PendingListRemark'
                        rows={2}
                        className='form-control form-control-sm'
                        placeholder={'Please enter a remark.'}
                        defaultValue={data.remark_content}
                        onChange={(e) => {
                          handleRemark(e.target.value)
                        }}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='form-group mb-2'>
                    <div className='d-flex flex-column'>
                      <label htmlFor='PendingListComment'>Comment</label>
                      <textarea
                        id='PendingListComment'
                        rows={2}
                        className='form-control form-control-sm'
                        placeholder={'Please enter a reason if you wish to return.'}
                        defaultValue={data.gp_verify_comment}
                        onChange={(e) => {
                          handleComment(e.target.value)
                        }}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className='col-md-12'>
                  <div className='form-group'>
                    <div className='d-flex justify-content-center flex-shrink-0'>
                      <button
                        className='btn btn-light-success btn-sm me-2'
                        onClick={() => {
                          submit('submit')
                        }}
                      >
                        <FontAwesomeIcon icon={faCheck} className='fa-fw me-1' />
                        Submit
                      </button>
                      <button
                        className='btn btn-light-info btn-sm'
                        onClick={() => {
                          submit('return')
                        }}
                      >
                        <FontAwesomeIcon icon={faRotateLeft} className='fa-fw me-1' />
                        Return
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export {PendingShow}
