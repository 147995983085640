const customStyles = {
  menuPortal: (base: any) => ({...base, zIndex: 9999}),
  singleValue: (provided: any) => ({
    ...provided,
    color: '#5e6278',
    // zIndex: 9999,
  }),
  control: (base: any, state: any) => ({
    ...base,
    fontWidth: '900',
    height: 34,
    minHeight: 34,
    // zIndex: 9999,
    backgroundColor: 'transparent',
    borderColor: state.isFocused ? '#d9d9d9' : '#d9d9d9',
    boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : 0,
    '&:hover': {
      borderColor: '#d9d9d9',
    },
  }),
  option: (base: any, state: any) => ({
    ...base,
    fontWidth: '900',
    color: 'black',
    // zIndex: 9999,
  }),
}

const customStyles2 = {
  menuPortal: (base: any) => ({...base, zIndex: 12}),
  singleValue: (provided: any) => ({
    ...provided,
    color: '#5e6278',
    zIndex: 12,
  }),
  control: (base: any, state: any) => ({
    ...base,
    fontWidth: '900',
    height: 34,
    minHeight: 34,
    zIndex: 12,
    backgroundColor: 'white',
    borderColor: state.isFocused ? '#d9d9d9' : '#d9d9d9',
    boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : 0,
    '&:hover': {
      borderColor: '#d9d9d9',
    },
  }),
  option: (base: any, state: any) => ({
    ...base,
    fontWidth: '900',
    color: 'black',
    zIndex: 12,
  }),
}

const customStylesGroup = {
  menuPortal: (base: any) => ({...base, zIndex: 9999}),
  singleValue: (provided: any) => ({
    ...provided,
    color: '#5e6278',
  }),
  control: (base: any, state: any) => ({
    ...base,
    fontWidth: '900',
    minHeight: 34,
    backgroundColor: 'transparent',
    borderColor: state.isFocused ? '#d9d9d9' : '#d9d9d9',
    boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0,123,255,.25)' : 0,
    '&:hover': {
      borderColor: '#d9d9d9',
    },
  }),
  option: (base: any, state: any) => ({
    ...base,
    fontWidth: '900',
    color: 'black',
  }),
}

export {customStyles, customStyles2, customStylesGroup}
