import React from 'react'
import {
    useTable, usePagination, useGlobalFilter,
    useAsyncDebounce,
    useFilters,
    useSortBy,
} from 'react-table'
import clsx from 'clsx'
import { Pagination } from '../table/Pagination'
import { KTSVG } from '../../../../_metronic/helpers'
import { QSExportData } from '../../../pages/quotations/Export'
import { useCurrentPage } from '../../../pages/master-setting/HistoryPage'

const QSHomeListTable = ({
    columns,
    data,
    fetchData,
    loading,
    status,
    pageCount: controlledPageCount,
    type,
}) => {
    const { currentPages } = useCurrentPage()

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable({
        columns,
        data,
        initialState: { pageIndex: 0 },
        manualPagination: true,
        pageCount: controlledPageCount,
    },
        usePagination
    )

    const setPage = () => {
        if (currentPages) {
            if (currentPages?.page == type) {
                if (currentPages?.status == true) {
                    setPageSize(currentPages?.size);
                    gotoPage(currentPages?.index);
                    currentPages.status = false;
                }
            }
        }
    }

    React.useEffect(() => {
        setPage()
        fetchData({ pageIndex, pageSize, status })
    }, [fetchData, pageIndex, pageSize, status])

    return (
        <>

            <div className='table-responsive' style={{ maxHeight: '550px' }}>
                <table {...getTableProps()} className='table table-sm table-hover table-row-bordered align-middle gy-4 gs-9 text-center'>
                    <thead className='border-bottom border-gray-200 fs-6 text-gray-600 fw-bold bg-light header-table-sticky'>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps}>
                                {headerGroup.headers.map((column) => (
                                    <th
                                        {...column.getHeaderProps([
                                            {
                                                className: column.headerClassName,
                                            },
                                        ])}
                                    >
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className='fw-semibold text-gray-600'>
                        {page.length > 0 ? (
                            <>
                                {page.map((row, i) => {
                                    prepareRow(row)
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map(cell => {
                                                return <td {...cell.getCellProps([
                                                    {
                                                        className: cell.column.columnClassName,
                                                    },
                                                ])}>{cell.render('Cell')}</td>
                                            })}
                                        </tr>
                                    )
                                })}
                                <tr>
                                    {loading ? (
                                        <td colSpan={columns.length}>Loading...</td>
                                    ) : (
                                        ''
                                    )}
                                </tr>
                            </>
                        ) : (
                            <tr>
                                <td colSpan={columns.length}>
                                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                        No matching records found
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className='header-table-sticky'>
                <Pagination pageOptions={pageOptions}
                    pageIndex={pageIndex}
                    gotoPage={gotoPage}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    pageCount={pageCount} />
            </div>
        </>
    )
}

export { QSHomeListTable }
