import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export const send_verify_quotation_url = `${API_URL}/send-verify-quotation`
export const send_return_requirement_url = `${API_URL}/send-return-requirement`
export const send_cancel_requirement_url = `${API_URL}/send-cancel-requirement`
export const send_updated_quotation_url = `${API_URL}/send-updated-quotation`

export function SendVerifyQuotation(quotation_list?: any) {
  return axios.post(send_verify_quotation_url, {quotation_list})
}

export function SendReturnRequirement(quotation_list?: any) {
  return axios.post(send_return_requirement_url, {quotation_list})
}

export function SendCancelRequirement(quotation_id?: number) {
  return axios.post(send_cancel_requirement_url, {quotation_id})
}

export function SendUpdatedQuotation(quotation_id?: number) {
  return axios.post(send_updated_quotation_url, {quotation_id})
}

